import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/bootstrap.min.css";

import "../../assets/css/font-awesome.min.css";
import "../../assets/css/themify-icons.css";
import "../../assets/css/flaticon-set.css";
import "../../assets/css/magnific-popup.css";
import "../../assets/css/owl.carousel.min.css";
import "../../assets/css/owl.theme.default.min.css";
import "../../assets/css/animate.css";
import "../../assets/css/bootsnav.css";
import "./homeStyles.css";
import "../../assets/css/responsive.css";
import logo from "../../assets/img/logo.png";
import whiteLogo from "../../assets/img/white-logo.png";
import banner from "../../assets/img/banner/1.jpg";
import shape5 from "../../assets/img/shape/bg-5.png";
import one from "../../assets/img/about/1.jpg";
import bg3 from "../../assets/img/shape/bg-3.png";
import about2 from "../../assets/img/about/2.jpg";
import clients1 from "../../assets/img/clients/Outsell-Logo.png";
import clients2 from "../../assets/img/clients/iskcon-logo.png";

import availability from '../../assets/img/icons/icon-4.svg'
import betterExp from '../../assets/img/icons/icon-5.svg'
import digi from '../../assets/img/icons/icon-6.svg'
import betterService from '../../assets/img/icons/icon-7.svg'
const Home = () => {
  const options = {
    items: 3,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
  };
  return (
    <div>
      {/* <div className="se-pre-con"></div> */}

      <header id="home">
        <nav className="navbar navbar-default attr-bg navbar-fixed dark no-background bootsnav">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-bars"></i>
              </button>
              <a className="navbar-brand" href="index.html">
                <img src={logo} className="logo" alt="Logo" />
              </a>
            </div>
          </div>
        </nav>
      </header>

      <a
        href="https://api.whatsapp.com/send?phone=+917386616587&amp;text=Hello,+I'm+interested+in+learning+more+about+the+services+Sassymonks+provides.+Could+you+please+share+details+about+what+you+offer?+&app_absent=0"
        target="_blank"
        className="scrollToTop"
        rel="noopener noreferrer"
      >
        <img
          src="https://fleetorder.in/assets/images/features/whatsapp.png"
          alt="Contact FleetOrder Via whatsapp"
        />
      </a>

      {/* <div className="banner-area content-bg circle-shape">
     
      <div
  className="bg"
  style={{ backgroundImage: `url(${banner})` }}
></div>

   
        <div className="item">
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-7 offset-lg-5">
                <div className="content">
                  <h2 className="fadeInUp">
                    A Company Powered By <strong>Passion</strong>
                  </h2>
                  <h1 className="fadeInDown">
                    Driven By <strong>Code</strong>
                  </h1>
                  <a href="#" className="btn-animate fadeInRight">
                    <span className="circle">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">LET'S TALK</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="banner-area content-bg circle-shape">
        <div className="bg" style={{ backgroundImage: `url(${banner})` }}></div>

        <div className="item">
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-7 offset-lg-5">
                <div className="content">
                  <h2 className=" fadeInUp">
                    A Company Powered By <strong>Passion</strong>
                  </h2>
                  <h1 className=" fadeInDown">
                    Driven By <strong>Code</strong>
                  </h1>
                  <a href="#" className="btn-animate  fadeInRight">
                    <span className="circle">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">LETS TALK</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-area bg-gray default-padding-top">
        <div className="fixed-shape-bottom">
          <img src={shape5} alt="Shape" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h2 className="area-title">
                  We create exceptional digital experiences
                </h2>
                <div className="devider"></div>
                <p>
                  At Sassymonks, we are dedicated to crafting outstanding
                  digital experiences that captivate and engage. Our team of
                  skilled professionals combines innovative technology with
                  creative vision to develop solutions that stand out in today’s
                  competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="services-items">
            <div className="row">
              <div className="item-grid col-lg-6 col-md-6">
                <div className="single-item">
                  <div className="item">
                    <div className="icon">
                      <i className="flaticon-growth"></i>
                    </div>
                    <div className="info">
                      <h4>
                        <a href="services.html">Web App Development</a>
                      </h4>
                      <p>
                        Bring your ideas to life with our web app development
                        services. Our expert team, armed with cutting-edge
                        technologies, delivers a complete end-to-end solution
                        tailored to your unique requirements.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="single-item">
                  <div className="item">
                    <div className="icon">
                      <i className="flaticon-sketch"></i>
                    </div>
                    <div className="info">
                      <h4>
                        <a href="services.html">Mobile App Development</a>
                      </h4>
                      <p>
                        Unlock the full potential of your business with mobile
                        applications built using technologies like React Native.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-grid col-lg-6 col-md-6">
                <div className="single-item">
                  <div className="item">
                    <div className="icon">
                      <i className="flaticon-cloud-network"></i>
                    </div>
                    <div className="info">
                      <h4>
                        <a href="services.html">Full Stack Development</a>
                      </h4>
                      <p>
                        Our full-stack expertise covers both front-end and
                        back-end development, delivering a comprehensive product
                        solution.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="single-item">
                  <div className="item">
                    <div className="icon">
                      <i className="flaticon-secure"></i>
                    </div>
                    <div className="info">
                      <h4>
                        <a href="services.html">Devops</a>
                      </h4>
                      <p>
                        Accelerate development and ensure continuous,
                        high-quality delivery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-content text-center">
              <p>
                Stop wasting time and spend money on technology.{" "}
                <a href="#">Let’s get started</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area center-responsive default-padding">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 info center-responsive"></div>
          </div>
        </div>
      </div>

      <div className="choose-us-area default-padding-bottom">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-5">
              <div className="thumb fadeInRight">
                <img src={one} alt="Thumb" />
                <div className="content fadeInLeft">
                  <h2>
                    10<span>+</span>
                  </h2>
                  <h5>Years of Experience</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1 info fadeInUp">
              <h2 className="area-title">
                What we promise is scalable digital solutions for businesses
              </h2>
              <p>
                We utilize cutting-edge technologies and frameworks that can
                evolve with your business, ensuring long-term viability and
                performance.
              </p>
              <ul>
                <li>Experience Team</li>
                <li>Best Practice for industry</li>
              </ul>
              <div className="contact">
                <p>Connect Us For Enquiry.</p>
                <h4>
                  <i className="fas fa-phone"></i> +91 7386616587
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      <div className="about-area half-bg bg-gray default-padding overflow-hidden">
        <div className='values-container'>
          <div className="about-content" >
            <div className="row">
              <div className="col-lg-7 info">
                <div className="top-info">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="item">
                        <div className="title">
                          <img src={availability}  style={{height:"45px",marginRight:'20px'}} alt="Fleetorder Service Availability"/>
                          <h4 >Availability</h4>
                        </div>
                        <p className="font-small mt-4">We are always available for you 24/7 for any queries</p>
                      </div>
                    </div>
                    
                
                    <div className="col-lg-6">
                      <div className="item">
                        <div className="title">
                        <img src={betterExp}  style={{height:"45px",marginRight:'20px'}} alt="Fleetorder Better User Experience"/>

                          <h4 >Better User Experience</h4>
                        </div>
                        <p className="font-small mt-4">
                        We gurantee you, Our team provides you the better User Experience
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="item">
                        <div className="title">
                          <img src={digi}  style={{height:"45px",marginRight:'20px'}} alt="Fleetorder Digitization"/>
                          <h4 >Digitization</h4>
                        </div>
                        <p className="font-small mt-4">We're here to convert all F&amp;B outlets to digital outlets</p>
                      </div>
                    </div>
                    
                
                    <div className="col-lg-6">
                      <div className="item">
                        <div className="title">
                        <img src={betterService}  style={{height:"45px",marginRight:'20px'}} alt="Fleetorder Better Service"/>

                          <h4 >Better Service</h4>
                        </div>
                        <p className="font-small mt-4">
                        Our experts provides you the best service for making you hassle free
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-info">
                  <h2>Few Of Our Clients</h2>
                  {/* <div className="clients-carousel-3-col owl-carousel owl-theme">
                  <OwlCarousel
                     
                    >
                      {clients.map((client) => (
                        <a key={client.id} href="#">
                          <img src={client.src} alt={client.alt} />
                        </a>
                      ))}
                    </OwlCarousel>
                         </div> */}
                  <OwlCarousel className="clients-carousel-3-col" {...options}>
                    <div>
                      <img className="img" src={clients1} style={{marginTop:'20px'}} />
                    </div>
                    <div>
                      <img className="img" src={clients2} style={{marginTop:"-30px",height:"150px"}}/>
                    </div>
{/* 
                    <div>
                      <img className="img" src={clients3} />
                    </div>

                    <div>
                      <img className="img" src={clients4} />
                    </div>

                    <div>
                      <img className="img" src={clients5} />
                    </div>

                    <div>
                      <img className="img" src={clients6} />
                    </div> */}
                  </OwlCarousel>
             
                </div>
              </div>
              <div className="col-lg-5 thumbs">
                <div className="thumb-items">
                  {/* <img src={about2} alt="Thumb" /> */}
                  <iframe width="500" height="500" src="https://www.youtube.com/embed/8BzZMnLPX7A?si=86bBw5fSONUm-MNc" title="Our Clients Feedback" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-light">
        <div className="fixed-shape">
          <img src={bg3} alt="Shape" />
        </div>

        <div className="container">
          <div className="f-items default-padding">
            <div className="row">
              <div className="col-lg-4 col-md-6 item">
                <div className="f-item about">
                  <img src={whiteLogo} alt="Logo" />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 item">
                <div className="f-item">
                  <h4 className="widget-title">Get In Touch</h4>
                  <div className="address">
                    <ul>
                      <li>
                        <strong>Address:</strong> Thub Phase 2, 20, Inorbit Mall
                        Rd, Vittal Rao Nagar, Madhapur, Hyderabad, Telangana
                        500081
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Copyright &copy; 2024. Powered by{" "}
                  <a href="#">Sassymonks Private Limited</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
